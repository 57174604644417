import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import moment from "moment";
import { Post, Get, FileUpload } from '../../Layout/ajax';
import { CModal, CImage } from '@coreui/react';
import { immediateToast } from "izitoast-react";
import { ButtonGroup } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Taskconfiguration from './Taskconfiguration';
import KBTasks from './TaskShow/KBTasks'
import NormalTasks from './TaskShow/NormalTasks'
const validationComment = Yup.object().shape({
    Taskcomment: Yup.string().required(),
});
const validationreason = Yup.object().shape({
    Reason: Yup.string().required(),
});
const Tasks = () => {
    const navigate = useNavigate();
    const [view, setView] = useState("kanboard");
    const [comment, setcomment] = useState(false);
    const [reason, setreason] = useState(false);
    const [initValueComment, setinitValueComment] = useState({
        Taskcomment: "",
    })
    const [initValuereason, setinitValuereason] = useState({
        Reason: "",
    })
    const [AllTask, setAllTask] = useState([]);
    const [addtaskcomment, setaddtaskcomment] = useState([]);
    const location = useLocation();
    const [users, setusers] = useState([]);
    const [Status, setStatus] = useState([]);
    const [inbox, setinbox] = useState([]);
    const [outbox, setoutbox] = useState([]);
    const [filteruser, setfilteruser] = useState(JSON.parse(window.localStorage.getItem('Id')));
    const [filterstatus, setfilterstatus] = useState("");
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [filtername, setfiltername] = useState("");
    const [task, settask] = useState(0);
    const showTost = (msg, theme) => {
        immediateToast(theme, {
            message: msg,
            timeout: 3000
        });
    }
    const taskcommit = async (val) => {
        settask(val);
        await Post(`task/getAlltaskcomment`, { "taskId": val })
            .then((res) => {
                setaddtaskcomment(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    useEffect(() => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setusers(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        getStatus();
        getAllProject();
    }, []);
    const [Process, setProcess] = useState([]);
    const [Project, setProject] = useState([]);
    // const [selectedProject, setSelectedProject] = useState(null);
    // const [selectedProcess, setSelectedProcess] = useState(null);
    const [filterProject, setFilterProject] = useState('');
    const [filterProcess, setFilterProcess] = useState('');
    useEffect(() => {
        getpost(true);
    }, [filteruser, filterstatus, filtername, filterProject, filterProcess]);
    // }, [filteruser, filterstatus, filtername, filterProject, selectedProcess]);
    const getAllProject = async () => {
        await Post(`contract/getAllContract`, { active: true })
            .then((res) => {
                setProject(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getAllProcess = async (projectId) => {
        if (projectId) {
            await Post(`contract/getAllProcess`, { contractId: projectId })
                .then((res) => {
                    setProcess(res.data);
                })
                .catch((err) => {
                    showTost("SOMETHING WENT WRONG ON SERVER", "error");
                });
        } else {
            setProcess([]);
        }
    }
    const getpost = async (val) => {
        await Post(`task/getAll`, { active: val, user: filteruser, status: filterstatus, filtername: filtername, projectId: filterProject, processId: filterProcess })
            .then((res) => {
                setinbox(res.data.Inbox);
                setoutbox(res.data.Outbox);
                setAllTask(res.data.AllList);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getStatus = async (val) => {
        Post(`master/getStatus`, { active: true })
            .then((res) => {
                if (location.state) {
                    setfilterstatus(location.state);
                }
                setStatus(res.data?.filter(f => f.GroupName == "Tasks"));
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const removetask = async (val) => {
        Swal.fire({
            title: "Are you sure want to delete ?",
            icon: "error",
            confirmButtonText: "Delete",
            confirmButtonColor: "#FD711A",
            showCancelButton: true,
            cancelButtonColor: "#0F3E6A",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await Post(
                    `task/remove/${val}`
                )
                    .then(res => {
                        if (res.success == false) {
                            showTost(res.msg, "warning");
                        } else {
                            getpost(true);
                        }
                    })
                    .catch((err) => {
                        showTost("SOMETHING WENT WRONG ON SERVER", "error");
                    });
            }
        });
    };
    const isValidObjectId = (id) => {
        return /^[0-9a-fA-F]{24}$/.test(id);
    };
    useEffect(() => {
        const role = JSON.parse(window.localStorage.getItem('RoleName'));
        const userId = JSON.parse(window.localStorage.getItem('Id'));
        if (role === "SuperAdmin") {
            setfilteruser(null);
        } else if (isValidObjectId(userId)) {
            setfilteruser(userId);
        } else {
            setfilteruser(null);
        }
    }, []);
    const Commentclear = () => {
        setinitValueComment({
            Taskcomment: "",
        });
    }
    const commenthandleSubmit = async (val) => {
        val.taskId = task;
        await Post(`task/addtaskcomment`, val)
            .then((res) => {
                if (res.success == true) {
                    showTost("Your data has been Successfully added", "success");
                    taskcommit(task); getpost(true);
                    genrateKeys();
                    Commentclear();
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const reasonhandleSubmit = async (val) => {
        val.taskId = task;
        await Post(`task/taskreason`, val)
            .then((res) => {
                if (res.success == true) {
                    showTost("Your data has been Successfully added", "success");
                    getpost(true);
                    genrateKeys();
                    setinitValuereason({
                        Reason: "",
                    });
                    setreason(false)
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const genrateKeys = () => {
        setFormKey(Math.random() * 1000000);
    }
    const [configuration, setconfiguration] = useState(false);
    return (
        <div className='content-wrapper'>
            <div className="row my-2 align-items-center">
                <div className="col-lg-7 col-12">
                    <div className='row'>
                        <span className='col-md-4'>
                            <div className='input-group'>
                                <span className='input-group-text'><i className="fa fa-th-large text-success" /></span>
                                <select className="form-select"
                                    value={filterProject}
                                    onChange={(e) => { setFilterProject(e.target.value); getAllProcess(e.target.value); }}
                                >
                                    <option value="">Select Project</option>
                                    {Project
                                        .map((x, i) => (
                                            <option key={i} value={x._id}>{x.Name}</option>
                                        ))}
                                </select>
                            </div>
                        </span>
                        <span className='col-md-4'>
                            <div className='input-group'>
                                <span className='input-group-text'><i className="fa fa-th-large text-success" /></span>
                                <select className="form-select"
                                    value={filterProcess}
                                    onChange={(e) => setFilterProcess(e.target.value)}
                                >
                                    <option value="">Select Process</option>
                                    {Process
                                        .map((x, i) => (
                                            <option key={i} value={x._id}>{x.Name}</option>
                                        ))}
                                </select>
                            </div>
                        </span>
                        <span className="col-md-4 mb-1 mb-md-0">
                            <input
                                type="text"
                                placeholder="Search Task Name"
                                value={filtername}
                                onChange={(event) => setfiltername(event.target.value)}
                                onKeyPress={(event) => {
                                    if (event.nativeEvent.key === 'Enter') {
                                        setfiltername(event.target.value);
                                    }
                                }}
                                className="form-control w-auto"
                            />
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-12">
                    <div className="float-right">
                        <div className='d-flex flex-wrap'>
                            <div>
                                <span className='btn btn-sm btn-outline-primary mr-2 float-right'
                                    onClick={() => navigate('/ArchivePage')}>
                                    View Archived Task
                                </span>
                            </div>
                            <div className='mr-2'>
                                <ButtonGroup>
                                    <Btn size="sm" onClick={() => {
                                        setView("normal");
                                        const roleName = JSON.parse(window.localStorage.getItem('RoleName'));
                                        if (roleName === "SuperAdmin") {
                                            setfilteruser(null);
                                        }
                                        setfiltername("");
                                        setFilterProject("");
                                        setFilterProcess("");
                                        getpost(true);
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                    </Btn>
                                    <Btn size="sm" onClick={() => {
                                        setView("kanboard");
                                        const roleName = JSON.parse(window.localStorage.getItem('RoleName'));
                                        if (roleName === "SuperAdmin") {
                                            setfilteruser(null);
                                        }
                                        setfiltername("");
                                        setFilterProject("");
                                        setFilterProcess("");
                                        getpost(true);
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg>
                                    </Btn>
                                </ButtonGroup>
                            </div>
                            <div>
                                <span className='btn btn-sm btn-warning mr-2 float-right'
                                    onClick={() => navigate(`/TaskAdd/0`)}>
                                    <i className="fa fa-plus mr-2" />
                                    Add Task
                                </span>
                            </div>
                            <div>
                                <span className='btn btn-sm btn-warning float-right' onClick={() => { setconfiguration(true); }}>
                                    Configuration
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <Taskconfiguration
                    Status={Status}
                    configuration={configuration}
                    setconfiguration={setconfiguration}
                    getpost={getpost}
                    getStatus={getStatus}
                    groupName="Tasks"
                />
            </div>
            <div className="mt-1">
                {view === "normal" && <NormalTasks
                    filteruser={filteruser}
                    setfilteruser={setfilteruser}
                    users={users}
                    filterstatus={filterstatus}
                    setfilterstatus={setfilterstatus}
                    Status={Status}
                    inbox={inbox}
                    settask={settask}
                    setreason={setreason}
                    taskcommit={taskcommit}
                    setcomment={setcomment}
                    removetask={removetask}
                    outbox={outbox}
                    getpost={getpost}
                />}
                {view === "kanboard" && <KBTasks
                    filteruser={filteruser}
                    setfilteruser={setfilteruser}
                    users={users}
                    Status={Status}
                    settask={settask}
                    setreason={setreason}
                    taskcommit={taskcommit}
                    setcomment={setcomment}
                    removetask={removetask}
                    AllTask={AllTask}
                    getpost={getpost}
                    showTost={showTost}
                />}
            </div>
            <CModal alignment="center" visible={reason}>
                <div className="modal-header">
                    <h4 className="modal-title">Add Reason</h4>
                    <button type="button" className="close" onClick={() => setreason(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <div class="row">
                        <Formik
                            onSubmit={reasonhandleSubmit}
                            initialValues={initValuereason}
                            validationSchema={validationreason}
                            key={formkey} >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                errors,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}>
                                        <div className="col-12 mb-1">
                                            <label>Reason</label>
                                            <input type="text" placeholder='Add Reason' name="Reason" defaultValue={values.Reason}
                                                className={!!touched.Reason && !!errors.Reason ? "form-control is-invalid" : "form-control"}
                                            />
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <button type="submit" className='btn font-weight-bold btn-success'>
                                                <i className="fa fa-check" /> Save
                                            </button>
                                        </div>
                                    </Form>)
                            }}
                        </Formik>
                    </div>
                </div>
            </CModal>
            <CModal alignment="center" size="lg" visible={comment}>
                <div className="modal-header">
                    <h4 className="modal-title">Add Task Comments</h4>
                    <button type="button" className="close" onClick={() => setcomment(false)}>&times;</button>
                </div>
                <div className="modal-body">
                    <div class="row">
                        <div className='col-lg-6 p-0'>
                            <Formik
                                onSubmit={commenthandleSubmit}
                                initialValues={initValueComment}
                                validationSchema={validationComment}
                                key={formkey} >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    touched,
                                    errors,
                                    setFieldValue,
                                }) => {
                                    return (
                                        <Form
                                            onSubmit={handleSubmit}
                                            onChange={handleChange}>
                                            <div className="col-12 mb-1">
                                                <label>Comment</label>
                                                <input type="text" placeholder='Add Comment' name="Taskcomment" defaultValue={values.Taskcomment}
                                                    className={!!touched.Taskcomment && !!errors.Taskcomment ? "form-control is-invalid" : "form-control"}
                                                />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <button type="submit" className='btn font-weight-bold btn-success'>
                                                    <i className="fa fa-check" /> Save
                                                </button>
                                            </div>
                                        </Form>)
                                }}
                            </Formik>
                        </div>
                        <div className='col-lg-6 pl-0'>
                            {addtaskcomment && addtaskcomment.map((value, idx) => {
                                return (
                                    <div key={idx} className="border mb-2 py-2" style={{ borderBottom: "1px solid lightgrey" }}>
                                        <div className='d-flex mb-1 px-3'>
                                            <span><i class="fa fa-comment-o text-primary"></i></span>
                                            <span className='ml-2'>{value.TaskComment}</span>
                                        </div>
                                        <div className='d-flex mb-1 px-3'>
                                            <span><i class="fa fa-user-o text-primary"></i></span>
                                            <span className='ml-2 pl-1'>{value.addedBy.name}</span>
                                        </div>
                                        <div className='d-flex mb-1 px-3'>
                                            <span><i class="fa fa-calendar-o text-primary"></i></span>
                                            <span className='ml-2 pl-1'>{moment(value.createdAt).format('lll')}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </CModal>
        </div>
    );
}

export default Tasks;