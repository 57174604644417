import './App.css';
import DefaultLayout from './Layout/DefaultLayout';
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
const socket = io(process.env.REACT_APP_APIURL, { transports: ['websocket'] });

function App() {
  const [notification, setNotification] = useState(null);
  useEffect(() => {
    let userid = localStorage.getItem('Id');
    socket.emit('setuserid', JSON.parse(userid));
    // alert('ok')    
    socket.on('notification', (message) => {
      const parsedMessage = JSON.parse(message);
      setNotification(parsedMessage);
      setTimeout(() => setNotification(null), 3600000);
    });



    const orgName = window.localStorage.getItem("DB_NAME");
    if (orgName) {      
        document.title = orgName;
    } else {    
        document.title = "Welcome";
    }
    const logo = window.localStorage.getItem("CompanyLogo");   
    if (logo) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${process.env.REACT_APP_APIURL}static/${logo}`;; 
    }
}, []);
  return (
    <div className="wrapper">
      {notification && (
        <div className="notification-box card">
          <div className="notification-header mb-1">
            <strong>{notification.type}</strong>
            <span onClick={() => setNotification(null)} className="close-btn border-0">×
            </span>
          </div>
          <div className="notification-body">
            <p className="my-1">{notification.description}</p>
            <a href={`/${notification.link}`} className="notification-link mt-2 btn btn-sm text-white" style={{backgroundColor: '#007bff'}}>
              Go to {notification.link}
            </a>
            <p className="notification-date mt-2 text-end">
              {new Date(notification.date).toLocaleString()}
            </p>
          </div>
        </div>
      )}
      <DefaultLayout />
    </div>
  );
}

export default App;
