import React, { useEffect } from 'react';
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link, useNavigate } from 'react-router-dom';
import { Post } from '../../../Layout/ajax';

const Tasks = ({ filteruser, setfilteruser, users, Status, settask, setreason, taskcommit, setcomment, removetask, getpost, AllTask, showTost }) => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   getpost(true);
  // }, []);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const taskId = result.draggableId;
      const newStatusId = destination.droppableId;
      try {
        await Post(`task/changeTaskStatus`, { id: taskId, Status: newStatusId });
        getpost(true);
      } catch (err) {
        console.log("Error updating task status:", err);
      }
    }
  };
  const archiveTask = async (Id, archivestatus) => {
    await Post(`task/archiveUnarchive`, { id: Id, archive: archivestatus })
      .then((res) => {
        if (res.success == true) {
          getpost(true);
        } else {
          showTost(res.msg, "warning");
        }
      }).catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
  };
  return (
    <div className=''>
      <div className="row my-2 d-flex justify-content-end">
        <div className="col-auto">
          {JSON.parse(window.localStorage.getItem('RoleName')) == "SuperAdmin" || JSON.parse(window.localStorage.getItem('RoleName')) == "Admin" ?
            <select className="form-select" value={filteruser} onChange={(e) => setfilteruser(e.target.value || null)} >
              <option value="">All</option>
              {users
                .map((x, i) => (
                  <option key={i} value={x._id}>{x.name}</option>
                ))}
            </select>
            : ""}
        </div>
      </div>

      {/* <div className="row mb-2 mt-2 ml-0">
        {Status.length === 0 ? (
          <div className="mt-5 text-center">No Record Found</div>
        ) : (
          Status.map((status, i) => (
            <div key={i} className="col-lg-3 col-md-4 col-sm-12 mb-3">
              <div className="card">
                <div className="card-header p-3" style={{ backgroundColor: status.Color }}>
                  <h5 className="text-white">
                    {status.Name}
                    <span>({outbox.filter((task) => task.Status._id === status._id).length})</span>
                  </h5>
                </div>
                <div className="card-body p-3">
                  <div className="custom-scrollbar pr-2" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {outbox
                      .filter((task) => task.Status._id === status._id)
                      .map((task, index) => (
                        <div
                          key={index}
                          className="card mb-2 py-2 px-2 text-dark"
                          style={{ background: "#f5f5f5" }}
                          onClick={() => getbyid(task._id)}
                          role="button"
                        >
                          <h6 className="mb-1">{task.Name}</h6>
                          <p className="mb-0">Priority: {task.Priority}</p>
                          <p className="mb-0">Assigned to: {task.Assign.name}</p>
                          <p className="mb-0">Start Date: {new Date(task.StartDate).toLocaleString()}</p>
                          <p className="mb-0">End Date: {new Date(task.EndDate).toLocaleString()}</p>
                        </div>
                      ))}
                    {outbox.filter((task) => task.Status._id === status._id).length === 0 && (
                      <div className="text-center text-muted">No tasks in this status</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div> */}
      {/* <div className="row mb-2 mt-2 ml-0">
        {Status.length === 0 ? (
          <div className="mt-5 text-center">No Record Found</div>
        ) : (
          Status.map((status, i) => (
            <div key={i} className="col-lg-3 col-md-4 col-sm-12 mb-3">
              <div className="card">
                <div className="card-header p-3" style={{ backgroundColor: status.Color }}>
                  <h5 className="text-white">
                    {status.Name}
                    <span>({inbox.filter((task) => task.Status._id === status._id).length})</span>
                  </h5>
                </div>
                <div className="card-body p-3">
                  <div className="custom-scrollbar pr-2" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {inbox
                      .filter((task) => task.Status._id === status._id) // Filter tasks by status
                      .map((task, index) => (
                        <div
                          key={index}
                          className="card mb-2 py-2 px-2 text-dark"
                          style={{ background: "#f5f5f5" }}
                          onClick={() => getbyid(task._id)}
                          role="button"
                        >
                          <h6 className="mb-1">{task.Name}</h6>
                          <p className="mb-0">Priority: {task.Priority}</p>
                          <p className="mb-0">Assigned to: {task.Assign.name}</p>
                          <p className="mb-0">Start Date: {new Date(task.StartDate).toLocaleString()}</p>
                          <p className="mb-0">End Date: {new Date(task.EndDate).toLocaleString()}</p>
                        </div>
                      ))}
                    {inbox.filter((task) => task.Status._id === status._id).length === 0 && (
                      <div className="text-center text-muted">No tasks in this status</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div> */}
      {/* <div className=" mb-2 mt-2 ml-0">
        {Status.length === 0 ? (
          <div className="mt-5 text-center">No Record Found</div>
        ) : (
          Status.map((status, i) => (
            <div key={i} className=" mb-3" style={{ maxWidth: '300px'}}>
              <div className="card">
                <div className="card-header p-3" style={{ backgroundColor: status.Color }}>
                  <h5 className="text-white">
                    {status.Name}
                    <span>({AllTask.filter((task) => task.Status._id === status._id).length})</span>
                  </h5>
                </div>
                <div className="card-body p-3">
                  <div className="custom-scrollbar pr-2" >
                    {AllTask
                      .filter((task) => task.Status._id === status._id)
                      .map((task, index) => (
                        <div
                          key={index}
                          className="card mb-2 py-2 px-2 text-dark"
                          style={{ background: "#f5f5f5" }}
                          onClick={() => getbyid(task._id)}
                          role="button"
                        >
                          <h6 className="mb-1">{task.Name}</h6>
                          <p className="mb-0">Priority: {task.Priority}</p>
                          <p className="mb-0">Assigned to: {task.Assign.name}</p>
                          <p className="mb-0">Start Date: {new Date(task.StartDate).toLocaleString()}</p>
                          <p className="mb-0">End Date: {new Date(task.EndDate).toLocaleString()}</p>
                        </div>
                      ))}
                    {AllTask.filter((task) => task.Status._id === status._id).length === 0 && (
                      <div className="text-center text-muted">No tasks in this status</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div> */}
      <div className="mb-2 mt-2 ml-0">
        {Status.length === 0 ? (
          <div className="mt-5 text-center">No Record Found</div>
        ) : (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="d-flex taskCard" style={{ whiteSpace: 'nowrap' }}>
              {Status.map((status) => (
                <Droppable key={status._id} droppableId={status._id}>
                  {(provided) => (
                    <div className="mr-2" ref={provided.innerRef} {...provided.droppableProps} style={{ minWidth: '280px' }}>
                      <div className="card">
                        <div className="card-header p-2" style={{ backgroundColor: status.Color }}>
                          <h5 className="text-white">
                            {status.Name}
                            <span>({AllTask.filter((task) => task.Status._id === status._id).length})</span>
                          </h5>
                        </div>
                        <div className="card-body p-2">
                          <div className="custom-scrollbar">
                            {AllTask.filter((task) => task.Status._id === status._id).map((task, index) => (
                              <Draggable key={task._id} draggableId={task._id} index={index}>
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <div className="card p-2 mb-2">
                                      <div>
                                        <div className="row mb-1">
                                          <div className='col-11'>
                                            <h6 className="text-capitalize text-wrap">{task.Name}</h6>
                                          </div>
                                        </div>
                                        <div className="row mb-1">
                                          <h6 className='col-4 font-weight-normal f-12 text-secondary'>Status </h6>
                                          <h6 className='col-8 f-12 text-capitalize' style={{ color: task.Status?.Color }}> {task.Status?.Name}</h6>
                                        </div>
                                        <div className="row mb-1">
                                          <h6 className='col-4 font-weight-normal f-12 text-secondary'>Priority</h6>
                                          <h6 className='col-8 f-12 text-capitalize'> {task.Priority}</h6>
                                        </div>

                                        <div className="row mb-1">
                                          <h6 className='col-4 font-weight-normal f-12 text-secondary'>Project Name</h6>
                                          <h6 className='col-8 f-12 text-capitalize'> {task.ProjectId?.Name}</h6>
                                        </div>
                                        <div className="row mb-1">
                                          <h6 className='col-4 font-weight-normal f-12 text-secondary'>Process Name</h6>
                                          <h6 className='col-8 f-12 text-capitalize'> {task.ProcessId?.Name}</h6>
                                        </div>

                                        <div className="row mb-1">
                                          <h6 className='col-4 font-weight-normal f-12 text-secondary'>Assign to</h6>
                                          <h6 className='col-8 text-dark f-12 text-capitalize'> {task.Assign?.name}</h6>
                                        </div>
                                        {
                                          task.EndDate === null ? " " : moment(task.EndDate).isBefore(moment()) && task.Reason ? (
                                            <div className='row mb-1'>
                                              <h6 className='col-4 font-weight-normal f-12 text-secondary'>End Date</h6>
                                              <h6 className='col-8 text-danger f-12'>
                                                {moment(task.EndDate).format('lll')}
                                              </h6>
                                            </div>
                                          ) : moment(task.EndDate).isBefore(moment()) ? (
                                            <div className='row mb-1'>
                                              <h6 className='col-4 font-weight-normal f-12 text-secondary'>End Date</h6>
                                              <h6 className='col-8 text-danger f-12'>
                                                {moment(task.EndDate).format('lll')}
                                                <i className="fa fa-clock-o btn btn-light btn-sm ml-1 f-12" title="add late reason" onClick={() => { settask(task._id); setreason(true) }} />
                                              </h6>
                                            </div>
                                          ) : (
                                            <div className='row mb-1'>
                                              <h6 className='col-4 font-weight-normal f-12 text-secondary'>End Date</h6>
                                              <h6 className="col-8 font-weight-normal f-12 text-secondary">
                                                {moment(task.EndDate).format('lll')}
                                              </h6>
                                            </div>
                                          )
                                        }
                                        <div className="row mb-1">
                                          <h6 className='col-4 font-weight-normal f-12 text-secondary'>Created By</h6>
                                          <h6 className='col-8 font-weight-normal f-12 text-capitalize text-secondary'> {task.addedBy?.name}</h6>
                                        </div>
                                        {task.Reason ? (
                                          <div className="row mb-1 ">
                                            <h6 className="col-4 font-weight-normal f-12 text-secondary">Reason</h6>
                                            <h6 className="col-8 font-weight-normal f-12 text-capitalize text-secondary">{task.Reason}</h6>
                                          </div>
                                        ) : null}
                                        {task.ProspectId?._id ? (
                                          <div className="d-flex row mb-1" onClick={() =>
                                            navigate('/Prospects', { state: { ProspectId: task.ProspectId?._id } })}>
                                            <h6 className="col-4 font-weight-normal f-12 text-secondary">Task Comapny</h6>
                                            <h6 className="col-8 font-weight-normal f-12 text-capitalize text-secondary">{task.ProspectId?.Company} </h6>
                                          </div>
                                        ) : " "
                                        }
                                        {task.LeadId?._id ? (
                                          <div className="d-flex row mb-1" onClick={() => navigate('/Leads', { state: { leadId: task.LeadId?._id } })}>
                                            <h6 className="col-4 font-weight-normal f-12 text-secondary">Task Comapny</h6>
                                            <h6 className="col-8 font-weight-normal f-12 text-capitalize text-secondary">{task.LeadId?.Company} </h6>
                                          </div>
                                        ) : " "
                                        }
                                        <div className="text-end mt-2 d-flex align-items-center flex-wrap justify-content-end">
                                          <button className="btn btn-secondary btn-sm px-1 py-0 mr-2" onClick={() => archiveTask(task._id, true)} >
                                            Archive
                                          </button>
                                          {/* {JSON.parse(window.localStorage.getItem('RoleName')) == "SuperAdmin" || JSON.parse(window.localStorage.getItem('RoleName')) == "Admin" ? */}
                                          <div className="d-flex">
                                            <span className="ml-auto">
                                              <i className={task.LastComment == null ? 'fa fa-comment-o btn btn-light btn-sm' : 'fa fa-comment-o btn btn-danger btn-sm'} onClick={() => { taskcommit(task._id); settask(task._id); setcomment(true) }} />
                                              <Link to={`/TaskAdd/${task._id}`}>
                                                <i className='btn btn-sm btn-success fa fa-pencil ml-2' />
                                              </Link>
                                              <i className='btn btn-danger btn-sm fa fa-trash ml-2' onClick={() => removetask(task._id)} /></span>
                                          </div>
                                          {/* : moment(task.EndDate).isBefore(moment()) ? "" :  
                                            <div className="d-flex">
                                              <span className="ml-auto">
                                                <i className={task.LastComment == null ? 'fa fa-comment-o btn btn-light btn-sm' : 'fa fa-comment-o btn btn-danger btn-sm'} onClick={() => { taskcommit(task._id); settask(task._id); setcomment(true) }} />
                                                <Link to={`/TaskAdd/${task._id}`}>
                                                  <i className='btn btn-sm btn-success fa fa-pencil ml-2' />
                                                </Link>
                                                <i className='btn btn-danger btn-sm fa fa-trash ml-2' onClick={() => removetask(task._id)} /></span>
                                            </div>
                                          } */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                            {AllTask.filter((task) => task.Status._id === status._id).length === 0 && (
                              <div className="text-center text-muted">No tasks in this status</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </DragDropContext>
        )}
      </div>
    </div>
  )
}
export default Tasks;