import React, { useState, useEffect, useRef } from 'react';
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Post, Get, FileUpload } from '../../Layout/ajax';
import { CModal } from '@coreui/react';
import * as Yup from "yup";
import Swal from "sweetalert2";
import moment from "moment";

export const SourceModal = ({ setsourceadd, sourceadd, getsourcepost, showTost, sourceval, setsourceval }) => {
    const sourcevalidation = Yup.object().shape({
        name: Yup.string().required(),
    });
    const [initsourceValue, setinitsourceValue] = useState({
        name: "",
    });

    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    useEffect(() => {
        if (sourceval != 0) {
            Get(`master/source/${sourceval}`)
                .then((res) => {
                    setinitsourceValue({
                        name: res.data.Name,
                    });
                    setsourceadd(true);
                    setsourceval(sourceval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch source details", "error");
                });
        }
    }, [sourceval]);
    const handleEdit = async (val) => {
        val.id = sourceval;
        await Post(`master/editSource`, val)
            .then((res) => {
                if (res.success == true) {
                    sourceclear();
                    setsourceadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getsourcepost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const sourceclear = () => {
        setinitsourceValue({
            name: "",
        });
    }
    const sourcehandleSubmit = async (val) => {
        await Post(`master/addSource`, val)
            .then((res) => {
                if (res.success == true) {
                    sourceclear();
                    getsourcepost();
                    setsourceadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={sourceadd}>
            <div className="modal-header">
                <h4 className="modal-title">{sourceval == 0 ? "Add Source" : "Edit Source"} </h4>
                <button type="button" className="close" onClick={() => { sourceclear(); setsourceadd(false); }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={sourceval == 0 ? sourcehandleSubmit : handleEdit}
                    initialValues={initsourceValue}
                    validationSchema={sourcevalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mt-2'>
                                    <label>Name</label>
                                    <input type='text' name="name" defaultValue={values.name} placeholder='Enter Source Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const ProductModal = ({ setproductadd, productadd, getproductpost, showTost, productval, setproductval }) => {
    const productvalidation = Yup.object().shape({
        name: Yup.string().required(),
    });
    const [SubCategory, setSubCategory] = useState([]);
    const [Category, setCategory] = useState([]);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initproductValue, setinitproductValue] = useState({
        id: "",
        name: "",
        code: "",
        category: "",
        subCategory: "",
        purchasePrice: "",
        salePrice: "",
        tax: "",
        minStock: "",
        maxStock: "",
        availableStock: "",
        description: "",
        type: "Product"
    });
    const productclear = () => {
        setinitproductValue({
            id: initproductValue.id == 0 ? null : 0,
            name: "",
            code: "",
            category: "",
            subCategory: "",
            purchasePrice: "",
            salePrice: "",
            tax: "",
            minStock: "",
            maxStock: "",
            availableStock: "",
            description: "",
            type: "Product"
        });
        setFormKey(Math.random() * 1000000);
    }
    const producthandleSubmit = async (val) => {
        await Post(`master/addProduct`, val)
            .then((res) => {
                if (res.success == true) {
                    productclear();
                    getproductpost();
                    setproductadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    useEffect(() => {
        if (productval != 0) {
            Get(`master/product/${productval}`)
                .then((res) => {
                    setinitproductValue({
                        name: res.data.Name,
                        code: res.data.Code,
                        category: res.data.Category,
                        subCategory: res.data.SubCategory,
                        purchasePrice: res.data.PurchasePrice,
                        salePrice: res.data.SalePrice,
                        tax: res.data.Tax,
                        minStock: res.data.MinStock,
                        maxStock: res.data.MaxStock,
                        availableStock: res.data.AvailableStock,
                        description: res.data.Description,
                        type: res.data.Type
                    });
                    setproductadd(true);
                    setproductval(productval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch product details", "error");
                });
        }
    }, [productval]);
    const handleEdit = async (val) => {
        val.id = productval;
        await Post(`master/editProduct`, val)
            .then((res) => {
                if (res.success == true) {
                    productclear();
                    setproductadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getproductpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    useEffect(() => {
        Post(`master/getCategorys`, { active: true })
            .then((res) => {
                setCategory(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getSubCategorys`, { active: true })
            .then((res) => {
                setSubCategory(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, [])
    return (
        <CModal alignment="center" visible={productadd}>
            <div className="modal-header">
                <h4 className="modal-title">
                    {productval == 0 ? "Add Product" : "Edit Product"}
                </h4>
                <button type="button" className="close" onClick={() => { productclear(); setproductadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={productval == 0 ? producthandleSubmit : handleEdit}
                    initialValues={initproductValue}
                    validationSchema={productvalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='row'>
                                    <div className='col-6 mb-2'>
                                        <label>Name</label>
                                        <input type='text' name="name" defaultValue={values.name} placeholder='Enter Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Code</label>
                                        <input type='text' name="code" defaultValue={values.code} placeholder='Enter Code' className={!!touched.code && !!errors.code ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Category</label>
                                        <select defaultValue={values.category} className={!!touched.category && !!errors.category ? "form-select is-invalid" : "form-select"} name='category'>
                                            <option value=''>Select Category</option>
                                            {Category
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>SubCategory</label>
                                        <select defaultValue={values.subCategory} className={!!touched.subCategory && !!errors.subCategory ? "form-select is-invalid" : "form-select"} name='subCategory'>
                                            <option value=''>Select SubCategory</option>
                                            {SubCategory
                                                .filter((x) => x.Category?._id == values.category)
                                                .map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Purchase Price</label>
                                        <input type='text' defaultValue={values.purchasePrice} name="purchasePrice" placeholder='Enter Purchase Price' className={!!touched.purchasePrice && !!errors.purchasePrice ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Sale Price</label>
                                        <input type='text' defaultValue={values.salePrice} name="salePrice" placeholder='Enter Sale Price' className={!!touched.salePrice && !!errors.salePrice ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Tax</label>
                                        <input type='text' defaultValue={values.tax} name="tax" placeholder='Enter Tax' className={!!touched.tax && !!errors.tax ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Min Stock</label>
                                        <input type='text' defaultValue={values.minStock} name="minStock" placeholder='Enter Min. Stock' className={!!touched.minStock && !!errors.minStock ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Max Stock</label>
                                        <input type='text' defaultValue={values.maxStock} name="maxStock" placeholder='Enter Max. Stock' className={!!touched.maxStock && !!errors.maxStock ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Available Stock</label>
                                        <input type='text' defaultValue={values.availableStock} name="availableStock" placeholder='Enter Available Stock' className={!!touched.availableStock && !!errors.availableStock ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <label>Description</label>
                                        <textarea type='text' defaultValue={values.description} name="description" placeholder='Enter Description' className={!!touched.description && !!errors.description ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const UserModal = ({ useradd, setuseradd, getuserpost, showTost, userval, setuserval }) => {
    const createuservalidation = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        password: Yup.string().required(),
        role: Yup.string().required(),
        confirmpassword: Yup.string().required()
            .oneOf([Yup.ref('password')], 'Passwords must and should match'),
    });
    const updateuservalidation = Yup.object().shape({
        name: Yup.string().required(),
        role: Yup.string().required(),
    });
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [role, setrole] = useState([]);
    useEffect(() => {
        Post(`master/getRole`, { active: true })
            .then((res) => {
                setrole(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const [createuserinitValue, setcreateuserinitValue] = useState({
        id: "",
        name: "",
        email: "",
        password: "",
        role: "",
        confirmpassword: ""
    });

    const [updateuserinitValue, setupdateuserinitValue] = useState({
        name: "",
        role: "",
    });
    useEffect(() => {
        if (userval != 0) {
            Get(`users/${userval}`)
                .then((res) => {
                    setupdateuserinitValue({
                        name: res.name,
                        role: res.role,
                    });
                    setuseradd(true);
                    setuserval(userval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch user details", "error");
                });
        }
    }, [userval]);
    const handleEdit = async (val) => {
        val.id = userval;
        await Post(`users/updateUser`, val)
            .then((res) => {
                userclear();
                setuseradd(false);
                Swal.fire({
                    title: "Your work has been saved",
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#FD711A",
                }).then(async ({ isConfirmed }) => {
                    if (isConfirmed) {
                        getuserpost(true);
                    }
                });
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const userclear = () => {
        setcreateuserinitValue({
            id: createuserinitValue.id == 0 ? null : 0,
            name: "",
            email: "",
            password: "",
            role: "",
            confirmpassword: ""
        })
    }
    const create = async (val) => {
        await Post(`users/add`, val)
            .then((res) => {
                if (res.token !== undefined) {
                    setuseradd(false);
                    getuserpost();
                    userclear();
                    showTost("Your data has been Successfully added", "success");
                }
                else if (res.message = "User Already Exists!") {
                    showTost("User Already Exists!", "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };

    return (
        <CModal alignment="center" visible={useradd}>
            <div className="modal-header">
                <h4 className="modal-title"> {userval == 0 ? "Add User" : "Edit User"}</h4>
                <button type="button" className="close" onClick={() => { userclear(); setuseradd(false); }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={userval == 0 ? create : handleEdit}
                    initialValues={userval == 0 ? createuserinitValue : updateuserinitValue}
                    validationSchema={userval == 0 ? createuservalidation : updateuservalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mt-2'>
                                    <label>User Name</label>
                                    <input type='text' name="name" defaultValue={values.name} placeholder='Enter User Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                </div>
                                {userval == 0 ? <>
                                    <div className='mt-2'>
                                        <label>User Email</label>
                                        <input type='email' name="email" placeholder='Enter User Email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Password</label>
                                        <input type='password' name="password" placeholder='Enter Password' className={!!touched.password && !!errors.password ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='mt-2'>
                                        <label>Confirm Password</label>
                                        <input type='password' name="confirmpassword" placeholder='Enter Confirm Password' className={!!touched.confirmpassword && !!errors.confirmpassword ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                </> : <></>}
                                <div className='mt-2'>
                                    <label>Role</label>
                                    <select defaultValue={values.role} className={!!touched.role && !!errors.role ? "form-select is-invalid" : "form-select"} name='role'>
                                        <option value=''>Select Role</option>
                                        {role
                                            .map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))}
                                    </select>
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const CustomerModal = ({ setcustomeradd, customeradd, getcustomerpost, showTost, Manual, customerval, setcustomerval }) => {
    const customervalidation = Yup.object().shape({
        company: Yup.string().required(),
        firstname: Yup.string().required(),
        email: Yup.string().email(),
        mobile: Yup.string().max(10).min(10).matches(/^[+]?[0-9]+$/),
    });
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initcustomerValue, setinitcustomerValue] = useState({
        id: "",
        company: "",
        gstno: "",
        title: "",
        firstname: "",
        lastname: "",
        designation: "",
        mobile: "",
        CustomerCode: "",
        email: "",
        address: "",
        city: "",
        state: "",
        notes: "",
    });
    const customerclear = () => {
        setinitcustomerValue({
            id: initcustomerValue.id == 0 ? null : 0,
            company: "",
            gstno: "",
            title: "",
            firstname: "",
            lastname: "",
            designation: "",
            CustomerCode: "",
            mobile: "",
            email: "",
            address: "",
            city: "",
            state: "",
            notes: "",
        });
    }
    useEffect(() => {
        if (customerval != 0) {
            Get(`customer/getCustomerById/${customerval}`)
                .then((res) => {
                    setinitcustomerValue({
                        company: res.data[0].Company,
                        gstno: res.data[0].GSTNo,
                        title: res.data[0].Title,
                        firstname: res.data[0].FirstName,
                        lastname: res.data[0].LastName,
                        designation: res.data[0].Designation,
                        CustomerCode: res.data[0].CustomerCode,
                        mobile: res.data[0].Mobile,
                        email: res.data[0].Email,
                        address: res.data[0].Address,
                        city: res.data[0].City?._id,
                        state: res.data[0].State?._id,
                        country: res.data[0].Country?._id,
                        notes: res.data[0].Notes,
                    });
                    setcustomeradd(true);
                    setcustomerval(customerval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch customer details", "error");
                });
        }
    }, [customerval]);
    const handleEdit = async (val) => {
        val.id = customerval;
        await Post(`customer/editCustomer`, val)
            .then((res) => {
                if (res.success == true) {
                    customerclear();
                    setcustomeradd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getcustomerpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const customerhandleSubmit = async (val) => {
        await Post(`customer/addCustomer`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.msg, "warning");
                }
                else {
                    customerclear();
                    setcustomeradd(false);
                    getcustomerpost();
                    showTost("Your data has been Successfully added", "success");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const [State, setState] = useState([]);
    const [Country, setCountry] = useState([]);
    const [City, setCity] = useState([]);
    const [lastCustomerCode, setLastCustomerCode] = useState('');
    useEffect(() => {
        Post(`master/getCountry`, { active: true })
            .then((res) => {
                setCountry(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getState`, { active: true })
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getCity`, { active: true })
            .then((res) => {
                setCity(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`customer/getAllCustomer`, { active: true })
            .then((res) => {
                setLastCustomerCode(res.lastCustomerCode?.CustomerCode)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, [])
    return (
        <CModal alignment="center" visible={customeradd}>
            <div className="modal-header">
                <h4 className="modal-title">
                    {customerval == 0 ? "Add Customer" : "Edit Customer"}
                </h4>
                <button type="button" className="close" onClick={() => { customerclear(); setcustomeradd(false); }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={customerval == 0 ? customerhandleSubmit : handleEdit}
                    initialValues={initcustomerValue}
                    validationSchema={customervalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='row'>
                                    {Manual?.Customer == true ?
                                        <div className="col-6 mb-2">
                                            <label>Customer Code</label>
                                            <input type="text" placeholder="Customer Code" value={values.CustomerCode} defaultValue={values.CustomerCode} name='CustomerCode' className={!!touched.CustomerCode && !!errors.CustomerCode ? "form-control is-invalid" : "form-control"} />
                                            {customerval == 0 ?
                                                <div>
                                                    <span>Last Customer Code:</span>
                                                    <span className='fw-bold ms-1'>
                                                        {lastCustomerCode}
                                                    </span>
                                                </div> : ""}
                                        </div> : ""}
                                    <div className='col-6 mb-2'>
                                        <label>Company Name</label>
                                        <input type='text' defaultValue={values.company} name="company" placeholder='Enter Company' className={!!touched.company && !!errors.company ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>GST No.</label>
                                        <input type="text" defaultValue={values.gstno} name='gstno' placeholder="GST No." className={!!touched.gstno && !!errors.gstno ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Title</label>
                                        <select defaultValue={values.title} className={!!touched.title && !!errors.title ? "form-select is-invalid" : "form-select"} name='title'>
                                            <option value="">Select</option>
                                            <option value="Mr.">Mr.</option>
                                            <option value="Mrs.">Mrs.</option>
                                            <option value="Ms.">Ms.</option>
                                        </select>
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>First Name</label>
                                        <input type='text' name="firstname" defaultValue={values.firstname} placeholder='Enter Firstname' className={!!touched.firstname && !!errors.firstname ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Last Name</label>
                                        <input type='text' name="lastname" defaultValue={values.lastname} placeholder='Enter Lastname' className={!!touched.lastname && !!errors.lastname ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Designation</label>
                                        <input type='text' name="designation" defaultValue={values.designation} placeholder='Enter Designation' className={!!touched.designation && !!errors.designation ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Mobile Number</label>
                                        <input type='text' name="mobile" defaultValue={values.mobile} placeholder='Enter Mobile' className={!!touched.mobile && !!errors.mobile ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Email</label>
                                        <input type='email' name="email" defaultValue={values.email} placeholder='Enter Email' className={!!touched.email && !!errors.email ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-6 mb-2'>
                                        <label>Address</label>
                                        <input type='text' name="address" defaultValue={values.address} placeholder='Enter Address' className={!!touched.address && !!errors.address ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className="col-6 mb-2">
                                        <label><i className="fa fa-globe text-danger mr-2" />Country</label>
                                        <select defaultValue={values.country} className={!!touched.country && !!errors.country ? "form-select is-invalid" : "form-select"} name='country'>
                                            <option>Select Country</option>
                                            {
                                                Country.map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <label><i className="fa fa-map-marker text-warning mr-2" />State</label>
                                        <select defaultValue={values.state} className={!!touched.state && !!errors.state ? "form-select is-invalid" : "form-select"} name='state'>
                                            <option>Select State</option>
                                            {State.filter((x) => x.Country?._id == values.country).map((x, i) => (
                                                <option key={i} value={x._id}>{x.Name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <label><i className="fa fa-fort-awesome text-success mr-2" />City</label>
                                        <select name='city' defaultValue={values.city} className={!!touched.city && !!errors.city ? "form-control is-invalid" : "form-control"}>
                                            <option>Select City</option>
                                            {
                                                City.filter((x) => x.State?._id == values.state).map((x, i) => (
                                                    <option key={i} value={x._id}>{x.Name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='col-12 mb-2'>
                                        <label>Notes</label>
                                        <textarea type='text' name="notes" defaultValue={values.notes} placeholder='Enter Notes' className={!!touched.notes && !!errors.notes ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const UnitModal = ({ setunitadd, unitadd, getunitpost, showTost, unitval, setunitval }) => {
    const unitvalidation = Yup.object().shape({
        name: Yup.string().required(),
    });
    const [initunitValue, setinitunitValue] = useState({
        name: "",
    });
    const unitclear = () => {
        setinitunitValue({
            name: "",
        });
    }

    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    useEffect(() => {
        if (unitval != 0) {
            Get(`master/unit/${unitval}`)
                .then((res) => {
                    setinitunitValue({
                        name: res.data.Name,
                    });
                    setunitadd(true);
                    setunitval(unitval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch unit details", "error");
                });
        }
    }, [unitval]);
    const handleEdit = async (val) => {
        val.id = unitval;
        await Post(`master/editUnit`, val)
            .then((res) => {
                if (res.success == true) {
                    unitclear();
                    setunitadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getunitpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const unithandleSubmit = async (val) => {
        await Post(`master/addUnit`, val)
            .then((res) => {
                if (res.success == true) {
                    unitclear();
                    getunitpost();
                    setunitadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={unitadd}>
            <div className="modal-header">
                <h4 className="modal-title">{unitval == 0 ? "Add Unit" : "Edit Unit"} </h4>
                <button type="button" className="close" onClick={() => { unitclear(); setunitadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={unitval == 0 ? unithandleSubmit : handleEdit}
                    initialValues={initunitValue}
                    validationSchema={unitvalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mt-2'>
                                    <label>Name</label>
                                    <input type='text' name="name" defaultValue={values.name} placeholder='Enter Unit Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const TypeModal = ({ settypeadd, typeadd, getType, showTost, typeval, settypeval }) => {
    const validationtype = Yup.object().shape({
        name: Yup.string().required(),
    });
    const [initValuetype, setinitValuetype] = useState({
        name: "",
    });
    const typeclear = () => {
        setinitValuetype({
            name: "",
        });
    }
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const handleSubmittype = async (val) => {
        await Post(`master/addType`, val)
            .then((res) => {
                if (res.success == true) {
                    typeclear();
                    getType()
                    settypeadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };

    useEffect(() => {
        if (typeval != 0) {
            Get(`master/Type/${typeval}`)
                .then((res) => {
                    setinitValuetype({
                        name: res.data.Name,
                    });
                    settypeadd(true);
                    settypeval(typeval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch type details", "error");
                });
        }
    }, [typeval]);
    const handleEdit = async (val) => {
        val.id = typeval;
        await Post(`master/editType`, val)
            .then((res) => {
                if (res.success == true) {
                    typeclear();
                    settypeadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getType(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={typeadd}>
            <div className="modal-header">
                <h4 className="modal-title">{typeval == 0 ? "Add Type" : "Edit Type"} </h4>
                <button type="button" className="close" onClick={() => { typeclear(); settypeadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={typeval == 0 ? handleSubmittype : handleEdit}
                    initialValues={initValuetype}
                    validationSchema={validationtype}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='row'>
                                    <div className='col-12 mb-2'>
                                        <label>Name</label>
                                        <input type='text' name="name" defaultValue={values.name} placeholder='Enter Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                    </div>
                                    <div className='col-12'>
                                        <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    )
}
export const IconModal = ({ seticonadd, iconadd, geticonpost, showTost, iconval, seticonval }) => {
    const iconvalidation = Yup.object().shape({
        name: Yup.string().required(),
    });
    const [initiconValue, setiniticonValue] = useState({
        name: "",
    });
    const iconclear = () => {
        setiniticonValue({
            name: "",
        });
    }
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const iconhandleSubmit = async (val) => {
        await Post(`master/addIcon`, val)
            .then((res) => {
                if (res.success == true) {
                    iconclear();
                    geticonpost();
                    seticonadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };

    useEffect(() => {
        if (iconval != 0) {
            Get(`master/icon/${iconval}`)
                .then((res) => {
                    setiniticonValue({
                        name: res.data.Name,
                    });
                    seticonadd(true);
                    seticonval(iconval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                });
        }
    }, [iconval]);
    const handleEdit = async (val) => {
        val.id = iconval;
        await Post(`master/editIcon`, val)
            .then((res) => {
                if (res.success == true) {
                    iconclear();
                    seticonadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            geticonpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={iconadd}>
            <div className="modal-header">
                <h4 className="modal-title">{iconval == 0 ? "Add Icon" : "Edit Icon"} </h4>
                <button type="button" className="close" onClick={() => { iconclear(); seticonadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={iconval == 0 ? iconhandleSubmit : handleEdit}
                    initialValues={initiconValue}
                    validationSchema={iconvalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mt-2'>
                                    <label>Icon Name</label>
                                    <input type='text' name="name" defaultValue={values.name} placeholder='Enter Icon Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const CommentModal = ({ taskcommit, id, genrateKeys, showTost, formkey, addtaskcomment }) => {
    const validationComment = Yup.object().shape({
        Taskcomment: Yup.string().required(),
    });
    const [initValueComment, setinitValueComment] = useState({
        Taskcomment: "",
    });
    const Commentclear = () => {
        setinitValueComment({
            Taskcomment: "",
        });
    }
    const commenthandleSubmit = async (val) => {
        val.taskId = id;
        await Post(`task/addtaskcomment`, val)
            .then((res) => {
                if (res.success == true) {
                    showTost("Your data has been Successfully added", "success");
                    taskcommit(id);
                    genrateKeys();
                    Commentclear();
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    return (
        <>
            <div className="row mb-4 mx-0">
                <div className="col-12">
                    <h6 className="">Activity</h6>
                </div>
            </div>
            <Formik
                onSubmit={commenthandleSubmit}
                initialValues={initValueComment}
                validationSchema={validationComment}
                key={formkey} >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                }) => {
                    return (
                        <Form
                            onSubmit={handleSubmit}
                            onChange={handleChange}>
                            <div class="row mx-0">
                                <div className="col-12 mb-1">
                                    <input type="text" placeholder='Add Comment' name="Taskcomment" defaultValue={values.Taskcomment}
                                        className={!!touched.Taskcomment && !!errors.Taskcomment ? "form-control is-invalid" : "form-control"}
                                    />
                                </div>
                                <div className='col-12 mt-2'>
                                    <button type="submit" className='btn font-weight-bold btn-success'>
                                        <i className="fa fa-check" /> Add Comment
                                    </button>
                                </div>
                            </div>

                        </Form>)
                }}
            </Formik>
            <div className='pt-4 px-3'>
                {addtaskcomment && addtaskcomment.map((value, idx) => {
                    return (
                        <div key={idx} className="border mb-2 py-2 px-3" style={{ borderBottom: "1px solid lightgrey" }}>
                            <div className='d-flex mb-1'>
                                <span><i class="fa fa-comment-o text-primary"></i></span>
                                <span className='ml-2'>{value.TaskComment}</span>
                            </div>
                            <div className='d-flex mb-1'>
                                <span><i class="fa fa-user-o text-primary"></i></span>
                                <span className='ml-2 pl-1'>{value.addedBy.name}</span>
                            </div>
                            <div className='d-flex mb-1'>
                                <span><i class="fa fa-calendar-o text-primary"></i></span>
                                <span className='ml-2 pl-1'>{moment(value.createdAt).format('lll')}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
export const ProcessModal = ({ setProcessadd, Processadd, getAllProcess, showTost, Processval, setProcessval, selectedProjectId }) => {
    const processValidation = Yup.object().shape({
        processName: Yup.string().required(),
        executive: Yup.string().required(),
        startDate: Yup.string().required(),
        dueDate: Yup.string().required(),
    });
    const [executive, setexecutive] = useState([]);
    const [initiProcessValue, setInitiProcessValue] = useState({
        contractId: selectedProjectId,
        processName: "",
        executive: "",
        startDate: "",
        dueDate: "",
        note: "",
    });
    const processclear = () => {
        setInitiProcessValue({
            contractId: selectedProjectId,
            processName: "",
            executive: "",
            startDate: "",
            dueDate: "",
            note: "",
        });
    }
    useEffect(() => {
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setexecutive(res);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const processhandleSubmit = async (val) => {
        val.name = val.processName;
        val.contractId = selectedProjectId;
        await Post(`contract/addProcess`, val)
            .then((res) => {
                if (res.success == true) {
                    processclear();
                    getAllProcess(selectedProjectId);
                    setProcessadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };

    useEffect(() => {
        if (Processval != 0) {
            Post(`contract/getProcessById`, { processId: Processval })
                .then((res) => {
                    setInitiProcessValue({
                        contractId: res.data.ProcessList[0].contractId,
                        processName: res.data.ProcessList[0].Name,
                        executive: res.data.ProcessList[0].executive,
                        startDate: moment(res.data.ProcessList[0].startDate).format("YYYY-MM-DD"),
                        dueDate: moment(res.data.ProcessList[0].dueDate).format("YYYY-MM-DD"),
                        note: res.data.ProcessList[0].note,
                    });
                    setProcessadd(true);
                    setProcessval(Processval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                });
        }
    }, [Processval]);
    const handleEdit = async (val) => {
        val.id = Processval;
        val.name = val.processName;
        val.contractId = selectedProjectId;
        await Post(`contract/editProcess`, val)
            .then((res) => {
                if (res.success == true) {
                    processclear();
                    setProcessadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getAllProcess(selectedProjectId);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    return (
        <CModal alignment="center" visible={Processadd}>
            <div className="modal-header">
                <h4 className="modal-title">{Processval == 0 ? "Add Process" : "Edit Process"} </h4>
                <button type="button" className="close" onClick={() => { processclear(); setProcessadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={Processval == 0 ? processhandleSubmit : handleEdit}
                    initialValues={initiProcessValue}
                    validationSchema={processValidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className='mt-2 col-6'>
                                    <label>Name</label>
                                    <input type='text' name="processName" defaultValue={values.processName} placeholder='Enter Name' className={!!touched.processName && !!errors.processName ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2 col-6'>
                                    <label>Executive</label>
                                    <div className='input-group'>
                                        <select defaultValue={values.executive} className={!!touched.executive && !!errors.executive ? "form-select is-invalid" : "form-select"} name='executive'>
                                            <option value=''>Select Executive</option>
                                            {executive.map((x, i) => (
                                                <option key={i} value={x._id}>{x.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6 mt-2">
                                    <label><i className="fa fa-calendar text-warning mr-2" />Start Date</label>
                                    <input type="date" placeholder="Start Date" defaultValue={values.startDate} name='startDate' className={!!touched.startDate && !!errors.startDate ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className="col-6 mt-2">
                                    <label><i className="fa fa-calendar text-warning mr-2" />Due Date</label>
                                    <input type="date" placeholder="Expiry Date" min={values.startDate} defaultValue={values.dueDate} name='dueDate' className={!!touched.dueDate && !!errors.dueDate ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2 col-12'>
                                    <label>Note</label>
                                    <textarea type='text' rows="3" defaultValue={values.note} name="note" placeholder='Enter Note' className={!!touched.note && !!errors.note ? "form-control is-invalid" : "form-control"} />
                                </div>
                                <div className='mt-2 col-12'>
                                    <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}
export const ProjectModal = ({ setProjectadd, Projectadd, getprojectpost, showTost, Projectval, setProjectval }) => {
    const projectvalidation = Yup.object().shape({
        name: Yup.string().required(),
        customer: Yup.string().required(),
        executive: Yup.string().required(),
        startDate: Yup.string().required(),
        expiryDate: Yup.string().required(),
        type: Yup.string().required(),
    });
    const inputRef = useRef(null);
    const [Customerlist, setCustomerlist] = useState([]);
    const [executive, setexecutive] = useState([]);
    const [type, settype] = useState([]);
    const [file, setfilepdf] = useState("");
    const [pdf, setpdf] = useState("");
    const [initProjectValue, setinitProjectValue] = useState({
        name: "",
        customer: "",
        executive: "",
        startDate: "",
        expiryDate: "",
        type: "",
        item: "",
        description: "",
        contractCharges: 0,
        renewalCharges: 0,
    });
    const projectclear = () => {
        setinitProjectValue({
            name: "",
            customer: "",
            executive: "",
            startDate: "",
            expiryDate: "",
            type: "",
            item: "",
            description: "",
            contractCharges: 0,
            renewalCharges: 0,
        });
    }
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const projecthandleSubmit = async (val) => {
        let formdata = new FormData();
        formdata.append("file", pdf);
        formdata.append("name", val.name);
        formdata.append("customer", val.customer);
        formdata.append("executive", val.executive);
        formdata.append("startDate", val.startDate);
        formdata.append("expiryDate", val.expiryDate);
        formdata.append("type", val.type);
        formdata.append("item", val.item);
        formdata.append("description", val.description);
        formdata.append("contractCharges", val.contractCharges);
        formdata.append("renewalCharges", val.renewalCharges);
        await FileUpload(`contract/addContract`, formdata)
            .then((res) => {
                if (res.success == true) {
                    projectclear();
                    getprojectpost();
                    setProjectadd(false);
                    showTost("Your data has been Successfully added", "success");
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    useEffect(() => {
        Post(`customer/getAllCustomer`, { active: true })
            .then((res) => {
                setCustomerlist(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`users/getAllUser`, { active: true })
            .then((res) => {
                setexecutive(res)
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
        Post(`master/getType`, { active: true })
            .then((res) => {
                settype(res.data);
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }, []);
    useEffect(() => {
        if (Projectval != 0) {
            Post(`contract/getContractById/${Projectval}`)
                .then((res) => {
                    setinitProjectValue({
                        name: res.data[0].Name,
                        customer: res.data[0].Customer?._id,
                        executive: res.data[0].executive?._id,
                        startDate: moment(res.data[0].StartDate).format("YYYY-MM-DD"),
                        expiryDate: moment(res.data[0].ExpiryDate).format("YYYY-MM-DD"),
                        type: res.data[0].Type,
                        item: res.data[0].Item,
                        description: res.data[0].Description,
                        contractCharges: res.data[0].ContractCharges,
                        renewalCharges: res.data[0].RenewalCharges,
                    });
                    setfilepdf(res.data[0].Files);
                    setProjectadd(true);
                    setProjectval(Projectval);
                    setFormKey(Math.random() * 1000000);
                }).catch((err) => {
                    showTost("Failed to fetch icon details", "error");
                });
        }
    }, [Projectval]);
    const handleEdit = async (val) => {
        let formdata = new FormData();
        formdata.append("id", Projectval);
        formdata.append("file", pdf);
        formdata.append("name", val.name);
        formdata.append("customer", val.customer);
        formdata.append("executive", val.executive);
        formdata.append("startDate", val.startDate);
        formdata.append("expiryDate", val.expiryDate);
        formdata.append("type", val.type);
        formdata.append("item", val.item);
        formdata.append("description", val.description);
        if (val.contractCharges && val.contractCharges !== 0) {
            formdata.append("contractCharges", val.contractCharges);
        }
        if (val.renewalCharges && val.renewalCharges !== 0) {
            formdata.append("renewalCharges", val.renewalCharges);
        }
        await FileUpload(`contract/editContract`, formdata)
            .then((res) => {
                if (res.success == true) {
                    projectclear();
                    setProjectadd(false);
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            getprojectpost(true);
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    };
    const setfile = async (val) => {
        setpdf(val.target.files[0]);
    };
    return (
        <CModal alignment="center" visible={Projectadd}>
            <div className="modal-header">
                <h4 className="modal-title">{Projectval == 0 ? "Add Project" : "Edit Project"} </h4>
                <button type="button" className="close" onClick={() => { projectclear(); setProjectadd(false) }}>&times;</button>
            </div>
            <div className="modal-body">
                <Formik
                    onSubmit={Projectval == 0 ? projecthandleSubmit : handleEdit}
                    initialValues={initProjectValue}
                    validationSchema={projectvalidation}
                    key={formkey}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                    }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                onChange={handleChange}>
                                <div className="col-12 mb-2">
                                    <div className="row">
                                        <div className='mt-2 col-12'>
                                            <label>Project Name</label>
                                            <input type='text' name="name" defaultValue={values.name} placeholder='Enter Project Name' className={!!touched.name && !!errors.name ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='mt-2 col-6'>
                                            <label>Customer</label>
                                            <div className='input-group'>
                                                <select defaultValue={values.customer} className={!!touched.customer && !!errors.customer ? "form-select is-invalid" : "form-select"} name='customer'>
                                                    <option value=''>Select Customer</option>
                                                    {Customerlist
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Company} ({x.FirstName} {x.LastName})</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='mt-2 col-6'>
                                            <label>Executive</label>
                                            <div className='input-group'>
                                                <select defaultValue={values.executive} className={!!touched.executive && !!errors.executive ? "form-select is-invalid" : "form-select"} name='executive'>
                                                    <option value=''>Select Executive</option>
                                                    {executive
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label><i className="fa fa-calendar text-warning mr-2" />Start Date</label>
                                            <input type="date" placeholder="Start Date" defaultValue={values.startDate} name='startDate' className={!!touched.startDate && !!errors.startDate ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label><i className="fa fa-calendar text-warning mr-2" />Expiry Date</label>
                                            <input type="date" placeholder="Expiry Date" min={values.startDate} defaultValue={values.expiryDate} name='expiryDate' className={!!touched.expiryDate && !!errors.expiryDate ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='mt-2 col-4'>
                                            <label>Type</label>
                                            <div className='input-group'>
                                                <select defaultValue={values.type} className={!!touched.type && !!errors.type ? "form-select is-invalid" : "form-select"} name='type'>
                                                    <option value=''>Select type</option>
                                                    {type
                                                        .map((x, i) => (
                                                            <option key={i} value={x._id}>{x.Name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='mt-2 col-8'>
                                            <label>Item</label>
                                            <input type='text' defaultValue={values.item} name="item" placeholder='Enter Item' className={!!touched.item && !!errors.item ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className='mt-2 col-12'>
                                            <label>Description</label>
                                            <textarea type='text' rows="3" defaultValue={values.description} name="description" placeholder='Enter Description' className={!!touched.description && !!errors.description ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label><i className="fa fa-inr mr-2" />Contract Charges</label>
                                            <input type='text' defaultValue={values.contractCharges} name="contractCharges" placeholder='Enter Contract Charges' className={!!touched.contractCharges && !!errors.contractCharges ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label><i className="fa fa-recycle mr-2" />Renewal Charges</label>
                                            <input type='text' defaultValue={values.renewalCharges} name="renewalCharges" placeholder='Enter Renewal Charges' className={!!touched.renewalCharges && !!errors.renewalCharges ? "form-control is-invalid" : "form-control"} />
                                        </div>
                                        <div className="col-12 mt-4">
                                            <span className='btn btn-sm btn-info mr-2' onClick={() => { inputRef.current.click(); }}>
                                                <i className="fa fa-file mr-2" /> <span>Attach File</span>
                                            </span>
                                            <input
                                                style={{ display: 'none' }}
                                                ref={inputRef}
                                                type="file"
                                                accept=".pdf"
                                                onChange={setfile}
                                            />
                                            <label className='mt-2'>{pdf !== "" ? pdf.name : Projectval !== 0 ? file : pdf.name}</label>
                                        </div>
                                        <div className='mt-2 col-12'>
                                            <button type="submit" className='btn btn-sm font-weight-bold btn-success'><i className="fa fa-check" /> Save</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </CModal>
    );
}


