import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Post, Get, FileUpload } from '../../../Layout/ajax';
import { immediateToast } from "izitoast-react";
import { useNavigate } from 'react-router-dom';

const ArchivePage = () => {
  const [Data, setData] = useState([]);
  const navigate = useNavigate();
  const [filterProject, setFilterProject] = useState('');
  const [filterProcess, setFilterProcess] = useState('');
  const [filtername, setfiltername] = useState("");
  const [filterstatus, setfilterstatus] = useState("");
  const [Process, setProcess] = useState([]);
  const [Project, setProject] = useState([]);
  const [Status, setStatus] = useState([]);
  useEffect(() => {
    getPost(true);
  }, [filterProject, filterProcess, filtername, filterstatus]);
  const showTost = (msg, theme) => {
    immediateToast(theme, {
      message: msg,
      timeout: 3000
    });
  }
  useEffect(() => {
    Post(`contract/getAllContract`, { active: true })
      .then((res) => {
        setProject(res.data);
      })
      .catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
    Post(`master/getStatus`, { active: true })
      .then((res) => {
        setStatus(res.data?.filter(f => f.GroupName == "Tasks"));
      })
      .catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
  }, []);
  const getAllProcess = async (projectId) => {
    if (projectId) {
      await Post(`contract/getAllProcess`, { contractId: projectId })
        .then((res) => {
          setProcess(res.data);
        })
        .catch((err) => {
          showTost("SOMETHING WENT WRONG ON SERVER", "error");
        });
    } else {
      setProcess([]);
    }
  }
  const getPost = async (val) => {
    await Post(`task/getAllArchiveTask`, { archive: val, status: filterstatus, filtername: filtername, projectId: filterProject, processId: filterProcess })
      .then((res) => {
        if (res.success == true) {
          setData(res.data.AllList);
          console.log(res.data.AllList);
        }
      }).catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
  }
  const archiveTask = async (Id, archivestatus) => {
    await Post(`task/archiveUnarchive`, { id: Id, archive: archivestatus })
      .then((res) => {
        if (res.success == true) {
          getPost(true);
        } else {
          showTost(res.msg, "warning");
        }
      }).catch((err) => {
        showTost("SOMETHING WENT WRONG ON SERVER", "error");
      });
  };
  return (
    <div className="container mt-4">
      <div className='row mb-3 align-items-center'>
        <div className='col-sm-5'>
          <h4> <i class="fa fa-archive" aria-hidden="true"></i> Archived Tasks</h4>
        </div>
        <span className='col-md-1 p-0'>
          <div className='input-group align-items-center filterCss position-relative'>
            {/* <i className="fa fa-search  filtericon" /> */}
            <select className="form-select filterCss" value={filterProject}
              onChange={(e) => { setFilterProject(e.target.value); getAllProcess(e.target.value); }} >
              <option value="">Project</option>
              {Project.map((x, i) => (<option key={i} value={x._id}>{x.Name}</option>))}
            </select>
          </div>
        </span>
        <span className='col-md-1 p-0'>
          <div className='input-group align-items-center filterCss position-relative'>
            {/* <i className="fa fa-search  filtericon" /> */}
            <select value={filterProcess} onChange={(e) => setFilterProcess(e.target.value)} className="form-select">
              <option>Process</option>
              {Process.map((x, i) => (<option key={i} value={x._id}>{x.Name}</option>))}
            </select>
          </div>
        </span>
        <span className='col-md-1'>
          <div className='input-group align-items-center filterCss position-relative'>
            {/* <i className="fa fa-search  filtericon" /> */}
            <select className="form-select" value={filterstatus} onChange={(e) => setfilterstatus(e.target.value)} >
              <option value="">All</option>
              {Status
                .map((x, i) => (
                  <option key={i} value={x._id}>{x.Name}</option>
                ))}
            </select>
          </div>
        </span>
        <span className="col-md-2 mb-1 mb-md-0">
          <div className='input-group align-items-center filterCss position-relative'>
            <i className="fa fa-search  filtericon" />
            <input
              type="text"
              placeholder="Search Task Name"
              value={filtername}
              onChange={(event) => setfiltername(event.target.value)}
              onKeyPress={(event) => {
                if (event.nativeEvent.key === 'Enter') {
                  setfiltername(event.target.value);
                }
              }}
              className="form-control"
            />
          </div>
        </span>
        <div className='col-sm-2'>
          <h4 className="btn btn-sm btn-primary mr-2 float-right" onClick={() => navigate('/Tasks')}>  View Task Page</h4>
        </div>
      </div>
      {/* <div className='row mb-3 align-items-center'>
        <span className='col-md-4'></span>
        <span className='col-md-1 p-0'>
          <div className='input-group align-items-center filterCss position-relative'>
            <i className="fa fa-search  filtericon" />
            <select className="form-select filterCss" value={filterProject}
              onChange={(e) => { setFilterProject(e.target.value); getAllProcess(e.target.value); }} >
              <option value="">Project</option>
              {Project.map((x, i) => (<option key={i} value={x._id}>{x.Name}</option>))}
            </select>
          </div>
        </span>
        <span className='col-md-1 p-0'>
          <div className='input-group align-items-center filterCss position-relative'>
            <i className="fa fa-search  filtericon" />
            <select value={filterProcess} onChange={(e) => setFilterProcess(e.target.value)} className="form-select">
              <option>Process</option>
              {Process.map((x, i) => (<option key={i} value={x._id}>{x.Name}</option>))}
            </select>
          </div>
        </span>
        <div className="col-sm-1">
          <div className='input-group align-items-center filterCss position-relative'>
            <i className="fa fa-search  filtericon" />
            <select className="form-select" value={filterstatus} onChange={(e) => setfilterstatus(e.target.value)} >
              <option value="">All</option>
              {Status
                .map((x, i) => (
                  <option key={i} value={x._id}>{x.Name}</option>
                ))}
            </select>
          </div>
        </div>
        <span className="col-md-2 mb-1 mb-md-0">
          <div className='input-group align-items-center filterCss position-relative'>
            <i className="fa fa-search  filtericon" />
            <input
              type="text"
              placeholder="Search Task Name"
              value={filtername}
              onChange={(event) => setfiltername(event.target.value)}
              onKeyPress={(event) => {
                if (event.nativeEvent.key === 'Enter') {
                  setfiltername(event.target.value);
                }
              }}
              className="form-control"
            />
          </div>
        </span>
      </div> */}
      {Data.length === 0 ? (
        <p className="text-center mt-4">No archived tasks found.</p>
      ) : (
        <div className="row">
          <table className='table'>
            <thead>
              <tr>
                <th>Task Name</th>
                <th>Status</th>
                <th>Project Name</th>
                <th>Process Name</th>
                <th>Assign To</th>
                <th>Created By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Data.map((task) => (
                <tr key={task._id}>
                  <td className='text-capitalize'>{task.Name}</td>
                  <td className='text-capitalize' style={{ color: task.Status?.Color }}>{task.Status?.Name}</td>
                  <td className='text-capitalize'>{task.ProjectId?.Name}</td>
                  <td className='text-capitalize'>{task.ProcessId?.Name}</td>
                  <td className='text-capitalize'>{task.Assign?.name}</td>
                  <td className='text-capitalize'>{task.addedBy?.name}</td>
                  <td className='text-capitalize'>
                    <button
                      className="btn btn-secondary btn-sm px-1 py-0"
                      onClick={() => archiveTask(task._id, false)}
                    >
                      Unarchive
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default ArchivePage